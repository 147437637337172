import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeFocusOrganization,
    changeLanguage,
    setEndTrialDate,
    setStatusTrial
} from "../../../redux/actions/actions";
import moment from "moment";
import requests from "../../axios/requests";

import { Input, message, Modal, Spin, Tooltip } from "antd";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import ImageCrop from "../CurrentCourse/ImageCorp/ImageCrop";
import PhoneMask from "../../components/reuseComponents/PhoneMask";
import Trial from "../../components/reuseComponents/Trial";
import UseWindowSize from "../../utils/hooks/hookResize";

import rus from "../../../images/country/russian_icon.png";
import usa_icon from "../../../images/country/usa_icon.png";
import fake_photos from "../../../images/icons/fake_photos.png";
import close_modal from "../../../images/icons/close_modal.svg";

import { ReactComponent as ArrowLanguage } from "../../../images/arrow.svg";
import { ReactComponent as QuestionIcon } from "../../../images/icons/question.svg";

const ReuseInput = (props) => {
    let {
        value, onChange, title, required = false, onBlur = null, error = false,
        placeholder = "", maxLen = "undefined", disabled = false
    } = props;

    const focusRef = useRef(null);
    return (
        <div className={"reuse_input-parent"}>
            {title &&
                <div className="title">{title} {required && <span style={{color: "#FF3434"}}>*</span>}</div>
            }
            <Input
                className={error ? "input_classic error" : "input_classic"}
                value={value}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                onBlur={onBlur}
                type="text"
                pattern="\d*"
                maxlength={maxLen}
                disabled={disabled}
                ref={focusRef}
            />
        </div>
    );
};
const ReuseCheckbox = (props) => {
    let {value, title, change, indx} = props;

    return (
        <div className="checkbox_group">
            <span>{title}</span>
            <div className="form-group">
                <input
                    type="checkbox"
                    id={"business" + indx}
                    checked={value}
                    onChange={(e) => change(e.target.checked)}
                />
                <label
                    htmlFor={"business" + indx}
                />
            </div>
        </div>
    );
};
const ReuseRowClient = (props) => {
    let {
        title, variable, clientProfile, setClientProfile, errors, validateFieldClient,
        setFlags = "", flags = "", info = "", whiteSpaceOff = false, needIconInfo = true,
        setErrors = false, required = true, customLength,
    } = props;
    const {language} = useSelector(state => state.Reducer);

    const variable_8 = variableLanguage({keyPage: "profile", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "profile", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "profile", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "profile", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "profile", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "profile", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "profile", keyVariable: "variable_14"});
    const variable_58 = variableLanguage({keyPage: "profile", keyVariable: "variable_58"});
    const variable_59 = variableLanguage({keyPage: "profile", keyVariable: "variable_59"});
    const variable_60 = variableLanguage({keyPage: "profile", keyVariable: "variable_60"});
    const variable_63 = variableLanguage({keyPage: "profile", keyVariable: "variable_63"});
    const variable_67 = variableLanguage({keyPage: "profile", keyVariable: "variable_67"});
    const variable_68 = variableLanguage({keyPage: "profile", keyVariable: "variable_68"});
    const variable_69 = variableLanguage({keyPage: "profile", keyVariable: "variable_69"});
    const variable_70 = variableLanguage({keyPage: "profile", keyVariable: "variable_70"});
    const variable_71 = variableLanguage({keyPage: "profile", keyVariable: "variable_71"});
    const variable_72 = variableLanguage({keyPage: "profile", keyVariable: "variable_72"});
    const variable_73 = variableLanguage({keyPage: "profile", keyVariable: "variable_73"});
    const variable_74 = variableLanguage({keyPage: "profile", keyVariable: "variable_74"});
    const variable_75 = variableLanguage({keyPage: "profile", keyVariable: "variable_75"});
    const variable_76 = variableLanguage({keyPage: "profile", keyVariable: "variable_76"});
    const variable_77 = variableLanguage({keyPage: "profile", keyVariable: "variable_77"});
    const variable_78 = variableLanguage({keyPage: "profile", keyVariable: "variable_78"});

    const [birthDay, setBirthDay] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [activeCountry, setActiveCountry] = useState("");
    const [flagsPhone, setFlagsPhone] = useState({
        dropdownPhone: false
    });
    const [activeMask, setActiveMask] = useState(0);

    const monthArr = ["",
        variable_67, variable_68, variable_69, variable_70, variable_71, variable_72,
        variable_73, variable_74, variable_75, variable_76, variable_77, variable_78
    ];
    const countryArr = [
        {value: "RUS", title: variable_8}, {value: "KAZ", title: variable_10},
        {value: "BLR", title: variable_9}, {value: "UZB", title: variable_12},
        {value: "AZE", title: variable_11}, {value: "GEO", title: variable_13},
        {value: "KGZ", title: variable_14}
    ];

    let validateCountCharacters = (currentField) => {
        let count = undefined;
        switch (currentField) {
            case "billing_descriptor":
                count = 14;
                break;
            case "ogrn":
                count = 13;
                break;
            case "account":
                count = 20;
                break;
            case "bik":
                count = 9;
                break;
            case "kor_account":
                count = 20;
                break;
            case "zip":
                count = 6;
                break;
            default:
                break;
        }
        if (customLength) count = 15
        return count;
    };
    let makeYears = (needUpYear = false) => {
        let arr = [];
        let num = moment().year();
        if (needUpYear)
            num += 10;

        for (let i = num; i > 1920; i--)
            arr = [...arr, i];
        return arr;
    };
    let displayCurrentCountry = (value) => {
        if (value) {
            switch (value) {
                case "RUS":
                    return language === "en" ? "Russia" : "Россия";
                case "KAZ":
                    return language === "en" ? "Kazakhstan" : "Казахстан";
                case "BLR":
                    return language === "en" ? "Belarus" : "Беларусь";
                case "UZB":
                    return language === "en" ? "Uzbekistan" : "Узбекистан";
                case "AZE":
                    return language === "en" ? "Azerbaijan" : "Азербайджан";
                case "GEO":
                    return language === "en" ? "Georgia" : "Грузия";
                case "KGZ":
                    return language === "en" ? "Kyrgyzstan" : "Киргизия";
                default:
                    return "";
            }
        }
        return "";
    };

    useEffect(() => {
        if (birthDay && birthMonth !== "" && birthYear) {
            let str = `${birthYear}-${birthMonth[0] === "0" ? birthMonth.slice(1) : birthMonth}-${birthDay}`;
            setClientProfile(prev => ({...prev, [variable]: str}));
        }
    }, [birthDay, birthMonth, birthYear]);
    useEffect(() => {
        if (clientProfile["ceo_birth_date"] && variable === "ceo_birth_date") {
            let splitDate = clientProfile["ceo_birth_date"].split("-");
            setBirthDay(splitDate[2]);
            setBirthMonth(splitDate[1]);
            setBirthYear(splitDate[0]);
        }
        if (clientProfile["ceo_country"] && variable === "ceo_country") {
            setActiveCountry(clientProfile["ceo_country"]);
        }
    }, [clientProfile]);
    return (
        <div className="row">
            <div
                className="name"
                style={whiteSpaceOff ? {whiteSpace: "inherit"} : {}}
            >
                <span>
                    {title}
                    {required &&
                        <span style={{color: "red", marginLeft: 5, fontSize: 19, lineHeight: "20px"}}>*</span>
                    }
                </span>
                {needIconInfo &&
                    <Tooltip align={"top"} title={info}>
                        <QuestionIcon/>
                    </Tooltip>
                }
            </div>
            <div className="input_group">
                <>
                    {title !== variable_58 && title !== variable_63 && title !== variable_60 && title !== variable_59
                        ? <ReuseInput
                            value={clientProfile[variable]}
                            maxLen={validateCountCharacters(variable)}
                            onChange={(e) => {
                                setClientProfile(prev => ({...prev, [variable]: e.target.value}));
                            }}
                            error={errors[variable] || false}
                            onBlur={() => {
                                if (variable === "ogrn") {
                                    if (customLength) {
                                        validateFieldClient("ogrn", clientProfile["ogrn"], 15, false, false, customLength);
                                    } else {
                                        validateFieldClient("ogrn", clientProfile["ogrn"], 13);
                                    }
                                }
                                if (variable === "account") {
                                    validateFieldClient("account", clientProfile["account"], 20);
                                }
                                if (variable === "bik") {
                                    validateFieldClient("bik", clientProfile["bik"], 9);
                                }
                                if (variable === "kor_account") {
                                    validateFieldClient("kor_account", clientProfile["kor_account"], 20);
                                }
                            }}
                            disabled={flags.disabledAll || false}
                        />
                        : <></>
                    }
                </>
                <>
                    {title === variable_58 &&
                        <div
                            className="birthday"
                            style={errors[variable] ? {borderColor: "red"} : {}}
                        >
                            <div
                                className="day"
                                tabIndex={0}
                                onClick={() => {
                                    if (!flags.disabledAll) {
                                        setFlags(prev => ({...prev, ["drop_down_day"]: !flags["drop_down_day"]}));
                                    }
                                }}
                                onBlur={() => setFlags(prev => ({...prev, ["drop_down_day"]: false}))}
                            >
                                <span>{birthDay}</span>
                                <div className="svg"><ArrowLanguage className={flags["drop_down_day"] ? "open" : ""}/>
                                </div>
                                <div className={flags["drop_down_day"] ? "drop_down open" : "drop_down"}>
                                    {Array(31).fill(0).map((elem, indx) =>
                                        <div
                                            key={indx}
                                            onClick={() => setBirthDay(indx + 1)}
                                        >
                                            {indx + 1}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="month"
                                tabIndex={0}
                                onClick={() => {
                                    if (!flags.disabledAll) {
                                        setFlags(prev => ({...prev, ["drop_down_month"]: !flags["drop_down_month"]}));
                                    }
                                }}
                                onBlur={() => setFlags(prev => ({...prev, ["drop_down_month"]: false}))}
                            >
                                <span>{monthArr[birthMonth]}</span>
                                <div className="svg"><ArrowLanguage className={flags["drop_down_month"] ? "open" : ""}/>
                                </div>
                                <div className={flags["drop_down_month"] ? "drop_down open" : "drop_down"}>
                                    {monthArr.map((elem, indx) => {
                                            if (indx !== 0) {
                                                return (
                                                    <div
                                                        key={indx}
                                                        onClick={() => setBirthMonth(indx)}
                                                    >
                                                        {elem}
                                                    </div>
                                                );
                                            }
                                        }
                                    )}
                                </div>
                            </div>
                            <div
                                className="year"
                                tabIndex={0}
                                onClick={() => {
                                    if (!flags.disabledAll) {
                                        setFlags(prev => ({...prev, ["drop_down_year"]: !flags["drop_down_year"]}));
                                    }
                                }}
                                onBlur={() => setFlags(prev => ({...prev, ["drop_down_year"]: false}))}
                            >
                                <span>{birthYear}</span>
                                <div className="svg"><ArrowLanguage className={flags["drop_down_year"] ? "open" : ""}/>
                                </div>
                                <div className={flags["drop_down_year"] ? "drop_down open" : "drop_down"}>
                                    {makeYears().map((elem, indx) =>
                                        <div
                                            key={indx}
                                            onClick={() => setBirthYear(elem)}
                                        >
                                            {elem}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </>
                <>
                    {title === variable_59 &&
                        <ReuseInput
                            value={clientProfile[variable]}
                            onChange={(e) => {
                                setClientProfile(prev => ({...prev, [variable]: e.target.value}));
                            }}
                            error={errors[variable] || false}
                            disabled={flags.disabledAll || false}
                        />
                        /* <PhoneMask
                             data={{phone: clientProfile[variable]}}
                             setChangeValue={(value) => {
                                 setClientProfile(prev => ({...prev, [variable]: value}));
                             }}
                             handleChangeFlags={(value) => setFlagsPhone(prev => ({...prev, ["dropdownPhone"]: value}))}
                             handleChangeFormProfile={(value, current) => {
                                 if (current === "activeMaskPhone")
                                     setActiveMask(value);
                                 else setClientProfile(prev => ({...prev, [variable]: ""}));
                             }}
                             flags={flagsPhone}
                             maskPhone={activeMask}
                             activeMaskPhone={activeMask}
                         />*/
                    }
                </>
                <>
                    {title === variable_60 &&
                        <div
                            className="seo_country"
                            style={errors[variable] ? {borderColor: "red"} : {}}
                            tabIndex={0}
                            onClick={() => {
                                if (!flags.disabledAll) {
                                    setFlags(prev => ({
                                        ...prev,
                                        ["drop_down_country_seo"]: !flags["drop_down_country_seo"]
                                    }));
                                }
                            }}
                            onBlur={() => setFlags(prev => ({...prev, ["drop_down_country_seo"]: false}))}
                        >
                            <div className="drop_down-parent">
                                <span>{displayCurrentCountry(activeCountry)}</span>
                                <div
                                    className={flags["drop_down_country_seo"] ? "drop_down-children open" : "drop_down-children"}>
                                    {countryArr.map((elem, indx) =>
                                        <div
                                            key={indx}
                                            onClick={() => {
                                                setActiveCountry(elem.value);
                                                setClientProfile(prev => ({...prev, [variable]: elem.value}));
                                            }}
                                        >
                                            {elem.title}
                                        </div>
                                    )}
                                </div>
                                <ArrowLanguage className={flags["drop_down_children"] ? "open" : ""}/>
                            </div>
                        </div>
                    }
                </>
            </div>
        </div>
    );
};
const ReuseRowClientAdress = (props) => {
    let {
        title, variable, clientAdress, setClientAdress, errors,
        classNameFlag, setFlags = "", flags = "", whiteSpaceOff = false,
        needIconInfo = true, validateFieldAdress = false, displayCurrentCountry = "", required = true,
    } = props;

    const variable_8 = variableLanguage({keyPage: "profile", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "profile", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "profile", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "profile", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "profile", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "profile", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "profile", keyVariable: "variable_14"});
    const variable_63 = variableLanguage({keyPage: "profile", keyVariable: "variable_63"});

    const countryArr = [
        {value: "RUS", title: variable_8}, {value: "KAZ", title: variable_10},
        {value: "BLR", title: variable_9}, {value: "UZB", title: variable_12},
        {value: "AZE", title: variable_11}, {value: "GEO", title: variable_13},
        {value: "KGZ", title: variable_14}
    ];
    return (
        <div className="row">
            <div
                className="name"
                style={whiteSpaceOff ? {whiteSpace: "inherit"} : {}}
            >
                {title}
                {required &&
                    <span style={{color: "red", marginLeft: 5, fontSize: 19, lineHeight: "20px"}}>*</span>
                }
                {needIconInfo &&
                    <QuestionIcon/>
                }
            </div>
            <div className="input_group">
                {title === variable_63
                    ? <div
                        className="country"
                        tabIndex={0}
                        onClick={() => {
                            if (!flags.disabledAll && !flags.disabledFact) {
                                setFlags(prev => ({...prev, [classNameFlag]: !flags[classNameFlag]}));
                            }
                        }}
                        onBlur={() => setFlags(prev => ({...prev, [classNameFlag]: false}))}
                        style={errors[variable + clientAdress.type] ? {borderColor: "red"} : {}}
                    >
                        <div className="drop_down-parent">
                            <span>{displayCurrentCountry}</span>
                            <div
                                className={flags[classNameFlag] ? "drop_down-children open" : "drop_down-children"}>
                                {countryArr.map((elem, indx) =>
                                    <div
                                        key={indx}
                                        onClick={() => {
                                            setClientAdress(clientAdress[variable], variable, elem.value);
                                        }}
                                    >
                                        {elem.title}
                                    </div>
                                )}
                            </div>
                            <ArrowLanguage className={flags[classNameFlag] ? "open" : ""}/>
                        </div>
                    </div>
                    : <ReuseInput
                        value={clientAdress[variable] || ""}
                        onChange={(e) => setClientAdress(clientAdress[variable], variable, e.target.value)}
                        error={errors[variable + clientAdress.type] || false}
                        maxLen={variable === "zip" ? 6 : undefined}
                        onBlur={() => {
                            if (variable === "zip") {
                                validateFieldAdress();
                            }
                        }}
                        disabled={flags.disabledAll || flags.disabledFact}
                    />
                }
            </div>
        </div>
    );
};
const Profile = () => {
    const {TextArea} = Input;
    const dispatch = useDispatch();
    const {language, trial_end_date, focusOnOrganizationBlock, trial_status} = useSelector(state => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "profile", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "profile", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "profile", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "profile", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "profile", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "profile", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "profile", keyVariable: "variable_7"});
    const variable_15 = variableLanguage({keyPage: "profile", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "profile", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "profile", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "profile", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "profile", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "profile", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "profile", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "profile", keyVariable: "variable_22"});
    const variable_24 = variableLanguage({keyPage: "profile", keyVariable: "variable_24"});
    const variable_26 = variableLanguage({keyPage: "profile", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "profile", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "profile", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "profile", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "profile", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "profile", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "profile", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "profile", keyVariable: "variable_33"});
    const variable_34 = variableLanguage({keyPage: "profile", keyVariable: "variable_34"});
    const variable_35 = variableLanguage({keyPage: "profile", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "profile", keyVariable: "variable_36"});
    const variable_37 = variableLanguage({keyPage: "profile", keyVariable: "variable_37"});
    const variable_38 = variableLanguage({keyPage: "profile", keyVariable: "variable_38"});
    const variable_39 = variableLanguage({keyPage: "profile", keyVariable: "variable_39"});
    const variable_40 = variableLanguage({keyPage: "profile", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "profile", keyVariable: "variable_41"});
    const variable_42 = variableLanguage({keyPage: "profile", keyVariable: "variable_42"});
    const variable_43 = variableLanguage({keyPage: "profile", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "profile", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "profile", keyVariable: "variable_45"});
    const variable_46 = variableLanguage({keyPage: "profile", keyVariable: "variable_46"});
    const variable_47 = variableLanguage({keyPage: "profile", keyVariable: "variable_47"});
    const variable_48 = variableLanguage({keyPage: "profile", keyVariable: "variable_48"});
    const variable_49 = variableLanguage({keyPage: "profile", keyVariable: "variable_49"});
    const variable_50 = variableLanguage({keyPage: "profile", keyVariable: "variable_50"});
    const variable_51 = variableLanguage({keyPage: "profile", keyVariable: "variable_51"});
    const variable_52 = variableLanguage({keyPage: "profile", keyVariable: "variable_52"});
    const variable_53 = variableLanguage({keyPage: "profile", keyVariable: "variable_53"});
    const variable_54 = variableLanguage({keyPage: "profile", keyVariable: "variable_54"});
    const variable_55 = variableLanguage({keyPage: "profile", keyVariable: "variable_55"});
    const variable_56 = variableLanguage({keyPage: "profile", keyVariable: "variable_56"});
    const variable_57 = variableLanguage({keyPage: "profile", keyVariable: "variable_57"});
    const variable_58 = variableLanguage({keyPage: "profile", keyVariable: "variable_58"});
    const variable_59 = variableLanguage({keyPage: "profile", keyVariable: "variable_59"});
    const variable_60 = variableLanguage({keyPage: "profile", keyVariable: "variable_60"});
    const variable_61 = variableLanguage({keyPage: "profile", keyVariable: "variable_61"});
    const variable_62 = variableLanguage({keyPage: "profile", keyVariable: "variable_62"});
    const variable_63 = variableLanguage({keyPage: "profile", keyVariable: "variable_63"});
    const variable_64 = variableLanguage({keyPage: "profile", keyVariable: "variable_64"});
    const variable_65 = variableLanguage({keyPage: "profile", keyVariable: "variable_65"});
    const variable_66 = variableLanguage({keyPage: "profile", keyVariable: "variable_66"});
    const variable_80 = variableLanguage({keyPage: "profile", keyVariable: "variable_80"});
    const variable_81 = variableLanguage({keyPage: "profile", keyVariable: "variable_81"});
    const variable_82 = variableLanguage({keyPage: "profile", keyVariable: "variable_82"});
    const variable_83 = variableLanguage({keyPage: "profile", keyVariable: "variable_83"});
    const variable_84 = variableLanguage({keyPage: "profile", keyVariable: "variable_84"});
    const variable_85 = variableLanguage({keyPage: "profile", keyVariable: "variable_85"});
    const variable_86 = variableLanguage({keyPage: "profile", keyVariable: "variable_86"});
    const variable_87 = variableLanguage({keyPage: "profile", keyVariable: "variable_87"});
    const variable_88 = variableLanguage({keyPage: "profile", keyVariable: "variable_88"});
    const variable_89 = variableLanguage({keyPage: "profile", keyVariable: "variable_89"});
    const variable_90 = variableLanguage({keyPage: "profile", keyVariable: "variable_90"});
    const variable_91 = variableLanguage({keyPage: "profile", keyVariable: "variable_91"});
    const variable_92 = variableLanguage({keyPage: "profile", keyVariable: "variable_92"});
    const variable_93 = variableLanguage({keyPage: "profile", keyVariable: "variable_93"});
    const variable_94 = variableLanguage({keyPage: "profile", keyVariable: "variable_94"});
    const variable_95 = variableLanguage({keyPage: "profile", keyVariable: "variable_95"});
    const variable_96 = variableLanguage({keyPage: "profile", keyVariable: "variable_96"});

    const [profileObj, setProfileObj] = useState({
        fio: "",
        phone: "",
        email: "",
        telegram: "",
        whatsapp: "",
        vk: "",
        language: "ru",
        mailing: true,
        avatar: "",
        activeMaskPhone: 0
    });
    const [registerInfo, setRegisterInfo] = useState(null);
    const [imageSrcProps, setImageSrcProps] = useState("");
    const [flags, setFlags] = useState({
        loading: false,
        dropdownPhone: false,
        dropdownLanguage: false,
        flagHiddenFotter: null,
        flagModalEditImage: false,
        drop_down_children: false,
        drop_down_country: false,
        drop_down_country_fact: false,
        drop_down_country_seo: false,
        checked_checkbox: false,
        organization_toggle: "organization",
        send_store_fail: false
    });
    const [scrollY, setScrollY] = useState(0);
    const [errors, setErrors] = useState({});
    const [width, height] = UseWindowSize();
    const [organizationValues, setOrganizationValues] = useState({
        short_name: "",
        contacts: "",
        payment_partner: false
    });
    const [clientProfile, setClientProfile] = useState({
        "full_name": "",
        "name": "",
        "email": "",
        "billing_descriptor": "",
        "inn": "",
        "kpp": "",
        "ogrn": "",
        "ceo_first_name": "",
        "ceo_last_name": "",
        "ceo_middle_name": "",
        "ceo_birth_date": "",
        "ceo_phone": "",
        "ceo_country": "",
        "account": "",
        "kor_account": "",
        "bank_name": "",
        "bik": ""
    });
    const [clientAdress, setClientAdress] = useState([
        {
            "type": "legal",
            "zip": "",
            "country": "",
            "city": "",
            "street": ""
        },
        {
            "type": "actual",
            "zip": "",
            "country": "",
            "city": "",
            "street": ""
        }
    ]);

    const arrLanguages = [
        <li onClick={() => {
            handleChangeFormProfile("ru", "language");
            dispatch(changeLanguage("ru"));
        }}>
            <img width={21} height={15} src={rus}/>
            <span>Русский</span>
        </li>,
        <li onClick={() => {
            handleChangeFormProfile("en", "language");
            dispatch(changeLanguage("en"));
        }}>
            <img width={21} height={15} src={usa_icon}/>
            <span>USA</span>
        </li>
    ];
    const inputPhotoRef = useRef();
    const organizationDataRef = useRef();
    const inputFullNameRef = useRef(null);

    let displayCurrentCountry = (value) => {
        if (value) {
            switch (value) {
                case "RUS":
                    return language === "en" ? "Russia" : "Россия";
                case "KAZ":
                    return language === "en" ? "Kazakhstan" : "Казахстан";
                case "BLR":
                    return language === "en" ? "Belarus" : "Беларусь";
                case "UZB":
                    return language === "en" ? "Uzbekistan" : "Узбекистан";
                case "AZE":
                    return language === "en" ? "Azerbaijan" : "Азербайджан";
                case "GEO":
                    return language === "en" ? "Georgia" : "Грузия";
                case "KGZ":
                    return language === "en" ? "Kyrgyzstan" : "Киргизия";
                default:
                    return "";
            }
        }
        return "";
    };
    let getLegalFields = () => {
        for (let key in clientAdress[0]) {
            if (!clientAdress[0][key]) {
                return false;
            }
        }
        return true;
    };
    let validateFieldClient = (currentFieldvalue, valueField, lengthSymbol, ref = false, clientAdressType = false,customLength = undefined) => {
        if (valueField.length < lengthSymbol || valueField.length > lengthSymbol) {
            let showErrorString = "";
            switch (currentFieldvalue) {
                case "billing_descriptor":
                    showErrorString = variable_93;
                    break;
                case "ogrn":
                    showErrorString = variable_82;
                    break;
                case "account":
                    showErrorString = variable_83;
                    break;
                case "bik":
                    showErrorString = variable_84;
                    break;
                case "kor_account":
                    showErrorString = variable_85;
                    break;
                default:
                    break;
            }
            if (customLength) showErrorString = variable_96
            message.error(showErrorString);
            setErrors(prev => ({...prev, [currentFieldvalue]: true}));
            setFlags((prev => ({...prev, ["loading"]: false})));
            setTimeout(() => {
                setErrors(prev => ({...prev, [currentFieldvalue]: false}));
            }, 3000);
            if (typeof ref?.current !== "undefined")
                ref.current.scrollIntoView({block: "center", behavior: "smooth"});
        }
    };
    let validateFieldAdress = () => {
        if (flags.organization_toggle === "organization") {
            if (clientAdress[0].zip.length < 6 || clientAdress[0].zip.length > 6) {
                message.error(variable_86);
                setErrors(prev => ({...prev, ["zip" + "legal"]: true}));
                setTimeout(() => {
                    setErrors(prev => ({...prev, ["zip" + "legal"]: false}));
                }, 3000);
                return true;
            }
            if (clientAdress[1].zip && (clientAdress[1].zip.length < 6 || clientAdress[0].zip.length > 6)) {
                message.error(variable_86);
                setErrors(prev => ({...prev, ["zip" + "actual"]: true}));
                setTimeout(() => {
                    setErrors(prev => ({...prev, ["zip" + "actual"]: false}));
                }, 3000);
                return true;
            }
        }
        if (flags.organization_toggle === "ip") {
            if (clientAdress[1].zip && (clientAdress[1].zip.length < 6 || clientAdress[1].zip.length > 6)) {
                message.error(variable_86);
                setErrors(prev => ({...prev, ["zip" + "actual"]: true}));
                setTimeout(() => {
                    setErrors(prev => ({...prev, ["zip" + "actual"]: false}));
                }, 3000);
                return true;
            }
        }
        return false;
    };
    let handleChangeFormProfile = (e, current) => {
        setProfileObj((prev) => ({...prev, [current]: e}));
    };
    let handleChangeFlags = (e, current) => {
        setFlags((prev) => ({...prev, [current]: e}));
    };
    let onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                customRequest(event.target.files[0]);
                setImageSrcProps(reader.result?.toString() || "");
                setFlags((prev) => ({...prev, ["flagModalEditImage"]: true}));
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    let customRequest = (file, closeModal) => {
        let fileTypes = "image/*";
        requests.storage.get_yandex_quest_key()
            .then((v) => {
                requests.storage.storage_upload(v.data.key, file, (progress) => {
                    var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                }, fileTypes)
                    .then((res) => {
                        if (closeModal) {
                            setFlags((prev) => ({...prev, ["flagModalEditImage"]: false}));
                            setImageSrcProps("");
                        }
                        setProfileObj(prev => ({
                            ...prev,
                            ["avatar"]: "https://storage.yandexcloud.net/skill-storage/" + v.data.key
                        }));
                        inputPhotoRef.current.value = "";
                    })
                    .catch((e) => {
                        console.log(e, "Ошибка загрузки файла.");
                    });
            });
    };

    let handleSendOrganication = () => {
        if (!organizationValues.short_name) return message.error(variable_27);
        if (!organizationValues.contacts) return message.error(variable_28);
        if (registerInfo !== null) {
            requests.auth
                .put_organization_info(organizationValues)
                .then((response) => {
                    message.success(variable_29);
                })
                .catch((e) => console.log(e));
        } else {
            requests.auth
                .post_organization_info(organizationValues)
                .then(() => {
                    message.success(variable_30);
                })
                .catch((e) => console.log(e));
        }

    };
    let handleEditProfile = () => {

        let formatObj = {
            fio: profileObj.fio,
            email: profileObj.email,
            avatar: profileObj.avatar,
            telegram_link: profileObj.telegram,
            whats_app_link: profileObj.whatsapp,
            vk_link: profileObj.vk,
            send_email: profileObj.mailing,
            phone: profileObj.phone
        };
        if (!profileObj.fio) return message.error(variable_32);
        if (profileObj.phone.includes("_") || !profileObj.phone) return message.error(variable_33);
        if (!profileObj.telegram) delete formatObj["telegram_link"];
        if (!profileObj.whatsapp) delete formatObj["whats_app_link"];
        if (!profileObj.vk) delete formatObj["vk_link"];

        requests.auth.edit_profile_new(formatObj)
            .then((res) => {
                if (profileObj.avatar)
                    localStorage.setItem("photo", profileObj.avatar);

                localStorage.setItem("language", language);
                message.success(variable_34);
            })
            .catch((error) => console.log(error, "error edit_profile"));
    };
    let handleEditAdress = (currentId, currentStr, value, legalOrActual) => {
        let newArr = [...clientAdress];
        let findInx = newArr.findIndex(x => x.type === legalOrActual);
        if (findInx !== -1) {
            newArr[findInx][currentStr] = value;
            setClientAdress(newArr);
        } else {
            let obj = {
                "type": legalOrActual,
                "zip": "",
                "country": "",
                "city": "",
                "street": ""
            };
            obj[currentStr] = value;
            newArr = [...newArr, obj];
            setClientAdress(newArr);
        }
    };
    let createOrganizationStore = (ip) => {
        // console.log("createOrganizationStore: ", {ip})
        setFlags((prev => ({...prev, ["loading"]: true})));

        let flagSendRequest = true;
        let factAdressNoEmpty = false;

        if (flags.organization_toggle === "organization") {
            for (let key in clientProfile) {
                if (key !== "ceo_middle_name" && !clientProfile[key]) {
                    flagSendRequest = false;
                    setErrors(prev => ({...prev, [key]: true}));
                    setTimeout(() => {
                        setErrors(prev => ({...prev, [key]: false}));
                    }, 3000);
                }
            }
            if (clientAdress && clientAdress.length > 0) {
                for (let key in clientAdress[0]) {
                    if (!clientAdress[0][key]) {
                        flagSendRequest = false;
                        setErrors(prev => ({...prev, [key + "legal"]: true}));
                        setTimeout(() => {
                            setErrors(prev => ({...prev, [key + "legal"]: false}));
                        }, 3000);
                    }
                    if (clientAdress[1][key] !== "actual" && clientAdress[1][key]) {
                        factAdressNoEmpty = true;
                    }
                }
                if (factAdressNoEmpty) {
                    for (let key in clientAdress[1]) {
                        if (!clientAdress[1][key]) {
                            flagSendRequest = false;
                            setErrors(prev => ({...prev, [key + "actual"]: true}));
                            setTimeout(() => {
                                setErrors(prev => ({...prev, [key + "actual"]: false}));
                            }, 3000);
                        }
                    }
                }
            }
        }
        if (flags.organization_toggle === "ip") {
            for (let key in clientProfile) {
                // kpp - необязательное поле
                // ceo_middle_name - необязательное поле (отчество)
                if (key !== "ceo_middle_name" && key !== "kpp" && !clientProfile[key]) {
                    flagSendRequest = false;
                    setErrors(prev => ({...prev, [key]: true}));
                    setTimeout(() => {
                        setErrors(prev => ({...prev, [key]: false}));
                    }, 3000);
                    setFlags((prev => ({...prev, ["loading"]: false})));
                    return message.error(variable_81)
                }
            }
            for (let key in clientAdress[1]) {
                // kpp - необязательное поле
                if (key !== "kpp" && !clientAdress[1][key]) {
                    flagSendRequest = false;
                    setErrors(prev => ({...prev, [key]: true}));
                    setTimeout(() => {
                        setErrors(prev => ({...prev, [key]: false}));
                    }, 3000);
                }
            }
            if (!clientProfile.kpp) {
                delete clientProfile.kpp
            }
            if (!flagSendRequest) {
                setFlags((prev => ({...prev, ["loading"]: false})));
                return message.error(variable_92);
            }
        }
        if (!flagSendRequest) {
            setFlags((prev => ({...prev, ["loading"]: false})));
            return message.error(variable_81);
        }
        if (ip) validateFieldClient("ogrn", clientProfile["ogrn"], 15, organizationDataRef, false, ip);
        else validateFieldClient("ogrn", clientProfile["ogrn"], 13, organizationDataRef);
        validateFieldClient("account", clientProfile["account"], 20, organizationDataRef);
        validateFieldClient("bik", clientProfile["bik"], 9, organizationDataRef);
        validateFieldClient("kor_account", clientProfile["kor_account"], 20, organizationDataRef);

        if (validateFieldAdress()) {
            return setFlags((prev => ({...prev, ["loading"]: false})));
        }
        console.log(clientProfile, 'clientProfile')

        if (typeof clientProfile.id !== "undefined") {
            requests.organization.organization_patch_store(clientProfile)
                .then((res_) => {
                        if (res_.data.status !== "approved") {
                            requests.organization.oraganization_send_store()
                                .then(() => {
                                    requests.organization.create_payment_init()
                                        .then(resp => {
                                            if (resp.data.payment_url) {
                                                window.location.replace(resp.data.payment_url);
                                            }
                                        }).catch(() => {
                                        setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                    });
                                })
                                .catch(err => {
                                    console.error(err, "error");
                                    setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                })
                                .finally(() => {
                                    setFlags(prev => ({...prev, ["loading"]: false}));
                                });
                        } else {
                            setFlags(prev => ({...prev, ["loading"]: false}));
                        }
                    }
                )
                .catch(err => {
                    console.error(err);
                    message.error(variable_80);
                    setFlags(prev => ({...prev, ["loading"]: false}));
                });

            if (clientAdress && clientAdress.length > 0) {
                console.log(clientAdress, 'click')
                if (flags.organization_toggle === "organization") {
                    for (let i = 0; i < clientAdress.length; i++) {
                        if (typeof clientAdress[i].id === "undefined") {
                            let flagFieldsNotEmpty = true;
                            for (let key in clientAdress[i]) {
                                if (!clientAdress[key]) {
                                    flagFieldsNotEmpty = false;
                                }
                            }
                            if (flagFieldsNotEmpty) {
                                requests.organization.oraganization_create_adress(clientAdress[i])
                                    .then((res) => {
                                        let newArr = [...clientAdress[i]];
                                        clientAdress[i].id = res.data.id;
                                        setClientAdress(newArr);
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        message.error(variable_80);
                                    });
                            }
                        } else {
                            requests.organization.oraganization_patch_adress(clientAdress[i].id, clientAdress[i])
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                });
                        }
                    }
                }
                if (flags.organization_toggle === "ip") {
                    if (typeof clientAdress[1].id === "undefined") {
                        let flagFieldsNotEmpty = true;
                        for (let key in clientAdress[1]) {
                            if (!clientAdress[key]) {
                                flagFieldsNotEmpty = false;
                            }
                        }
                        if (flagFieldsNotEmpty) {
                            requests.organization.oraganization_create_adress(clientAdress[1])
                                .then((res) => {
                                    let newArr = [...clientAdress[1]];
                                    clientAdress[1].id = res.data.id;
                                    setClientAdress(newArr);
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                });
                        }
                    } else {
                        requests.organization.oraganization_patch_adress(clientAdress[1].id, clientAdress[1])
                            .catch(err => {
                                console.error(err);
                                message.error(variable_80);
                            });
                    }
                }
            }
        }
        else {
            requests.organization.organization_create_store(clientProfile)
                .then(res_store => {
                    setClientProfile((prev) => ({...prev, ["id"]: res_store.data?.id}))
                    if (flags.organization_toggle === "organization") {
                        let flagAdressNoEmpty = true;
                        for (let key in clientAdress[1]) {
                            if (!clientAdress[1][key])
                                flagAdressNoEmpty = false;
                        }
                        if (flagAdressNoEmpty) { // если фактический адрес заполнен
                            requests.organization.oraganization_create_adress(clientAdress[0])
                                .then((res) => {
                                    let newArr = [...clientAdress[0]];
                                    clientAdress[0].id = res.data.id;
                                    setClientAdress(newArr);
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                });
                            requests.organization.oraganization_create_adress(clientAdress[1])
                                .then((res_adress) => {
                                    let newArr = [...clientAdress[1]];
                                    clientAdress[1].id = res_adress.data.id;
                                    setClientAdress(newArr);

                                    if (res_store.data.status !== "approved") {
                                        requests.organization.oraganization_send_store()
                                            .then(res_send => {
                                                /*message.success(variable_79);*/
                                                requests.organization.create_payment_init()
                                                    .then(resp => {
                                                        if (resp.data.payment_url) {
                                                            window.location.replace(resp.data.payment_url);
                                                        }
                                                    }).catch(() => {
                                                    setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                                });
                                                /*setFlags(prev => ({...prev, ["disabledAll"]: true}));*/
                                            })
                                            .catch(err => {
                                                console.error(err, "new error 2&&&&&");
                                                message.error(variable_80);
                                                setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                            })
                                            .finally(() => {
                                                setFlags(prev => ({...prev, ["loading"]: false}));
                                            })
                                        ;
                                    }
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                });
                        } else {
                            requests.organization.oraganization_create_adress(clientAdress[0])
                                .then((res_adress) => {
                                    let newArr = [...clientAdress[0]];
                                    clientAdress[0].id = res_adress.data.id;
                                    setClientAdress(newArr);

                                    if (res_store.data.status !== "approved") {
                                        requests.organization.oraganization_send_store()
                                            .then(() => {
                                                /*  message.success(variable_79);*/
                                                requests.organization.create_payment_init()
                                                    .then(resp => {
                                                        if (resp.data.payment_url) {
                                                            window.location.replace(resp.data.payment_url);
                                                        }
                                                    }).catch(() => {
                                                    setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                                });
                                                /*setFlags(prev => ({...prev, ["disabledAll"]: true}));*/
                                            })
                                            .catch(err => {
                                                console.error(err, "new error 3&&&&&");
                                                message.error(variable_80);
                                                setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                            })
                                            .finally(() => {
                                                setFlags(prev => ({...prev, ["loading"]: false}));
                                            });
                                    }
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                    setFlags(prev => ({...prev, ["loading"]: false}));
                                });
                        }
                    }
                    if (flags.organization_toggle === "ip") {
                        let flagAdressNoEmpty = true;
                        for (let key in clientAdress[1]) {
                            if (!clientAdress[1][key])
                                flagAdressNoEmpty = false;
                        }
                        if (flagAdressNoEmpty) { // если фактический адрес заполнен
                            requests.organization.oraganization_create_adress(clientAdress[1])
                                .then((res_adress) => {
                                    let newArr = [...clientAdress[1]];
                                    clientAdress[1].id = res_adress.data.id;
                                    setClientAdress(newArr);

                                    if (res_store.data.status !== "approved") {
                                        requests.organization.oraganization_send_store()
                                            .then(res_send => {
                                                requests.organization.create_payment_init()
                                                    .then(resp => {
                                                        if (resp.data.payment_url) {
                                                            window.location.replace(resp.data.payment_url);
                                                        }
                                                    }).catch(() => {
                                                    setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                                });
                                            })
                                            .catch(err => {
                                                console.error(err, "new error 2&&&&&");
                                                message.error(variable_80);
                                                setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                            })
                                            .finally(() => {
                                                setFlags(prev => ({...prev, ["loading"]: false}));
                                            })
                                    }
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error(variable_80);
                                });
                        } else {
                            if (res_store.data.status !== "approved") {
                                requests.organization.oraganization_send_store()
                                    .then(() => {
                                        requests.organization.create_payment_init()
                                            .then(resp => {
                                                if (resp.data.payment_url) {
                                                    window.location.replace(resp.data.payment_url);
                                                }
                                            }).catch(() => {
                                            setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                        });
                                    })
                                    .catch(err => {
                                        console.error(err, "new error 3&&&&&");
                                        message.error(variable_80);
                                        setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                                    })
                                    .finally(() => {
                                        setFlags(prev => ({...prev, ["loading"]: false}));
                                    });
                            }
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                    message.error(variable_80);
                    // setFlags(prev => ({...prev, ["send_store_fail"]: true}));
                    setFlags(prev => ({...prev, ["loading"]: false}));
                });
        }
        setTimeout(() => {
            setFlags((prev => ({...prev, ["loading"]: false})));
        }, 7000);
    };
    let regExStreet = (str) => {
        let newStr = str.replace(/[^\.\-\,\dA-zА-яё ]/g, '').replace(/[\\`]/, "")
        return newStr;
    }

    useEffect(() => {
        requests.auth.get_organization_info()
            .then((v) => {
                if (v.status === 200) {
                    setOrganizationValues({
                        short_name: v.data.short_name,
                        contacts: v.data.contacts,
                        payment_partner: v.data.payment_partner

                    });
                    setRegisterInfo(v.data);
                }
            })
            .catch(err => console.error(err));

        requests.auth.get_profile_new()
            .then((res) => {
                setProfileObj((prev) => ({...prev, ["fio"]: res.data.fio}));
                setProfileObj((prev) => ({...prev, ["phone"]: res.data.phone}));
                setProfileObj((prev) => ({...prev, ["mailing"]: res.data.send_email}));
                setProfileObj((prev) => ({...prev, ["email"]: res.data.email}));
                setProfileObj((prev) => ({...prev, ["telegram"]: res.data.telegram_link}));
                setProfileObj((prev) => ({...prev, ["whatsapp"]: res.data.whats_app_link}));
                setProfileObj((prev) => ({...prev, ["vk"]: res.data.vk_link}));
                setProfileObj((prev) => ({...prev, ["avatar"]: res.data.avatar}));

                if (res.data?.organization?.status === 0) {
                    dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    dispatch(setStatusTrial(0));
                }

                if (res.data?.organization?.status === 1) {
                    if (res.data?.organization?.trial_end_date) {
                        dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    }
                    dispatch(setStatusTrial(1));
                }
                if (res.data?.organization?.status === 2) {
                    dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    dispatch(setStatusTrial(2));
                }
            });
        requests.organization.get_organization_store()
            .then(res_store => {
                setClientProfile(res_store.data);
                if (res_store.data.status === "approved") {
                    dispatch(setEndTrialDate(""));
                    /*setFlags(prev => ({...prev, ["disabledAll"]: true}));*/
                }
                if (res_store.data.kpp === "000000000")
                    setFlags((prev) => ({...prev, ["organization_toggle"]: "ip"}))

                requests.organization.get_organization_adress()
                    .then(res_adress => {
                        let newArr = [...clientAdress];

                        let findInd = res_adress.data.findIndex(elem => elem.type === "legal");
                        if (findInd !== -1)
                            newArr[0] = res_adress.data[findInd];

                        let findIndx = res_adress.data.findIndex(elem => elem.type === "actual");
                        if (findIndx !== -1)
                            newArr[1] = res_adress.data[findIndx];

                        setClientAdress(newArr);
                    })
                    .catch(err => console.error(err));
            })
            .catch(err => {
                console.error(err)
                // setFlags((prev) => ({...prev, ["send_store_fail"]: true}));
            });
    }, []);
    useEffect(() => {
        setProfileObj((prev) => ({...prev, ["language"]: language}));
    }, [language]);
    useEffect(() => {
        if (focusOnOrganizationBlock) {
            if (typeof organizationDataRef?.current !== "undefined")
                organizationDataRef.current.scrollIntoView({block: "center", behavior: "smooth"});
            setTimeout(() => {
                dispatch(changeFocusOrganization(false));
            }, 1500);
        }
    }, [focusOnOrganizationBlock]);
    return (
        <div className={"profile"}>
            {(trial_status === 0 || trial_status === 1) && width <= 820 && <Trial hideBtn={true}/>}
            <div className="info_profile">
                <div className="photo_profile">
                    <div className="top">
                        <div className="left">
                            <img
                                src={profileObj.avatar || fake_photos}
                                style={{maxWidth: 79, borderRadius: 16}}
                                key={profileObj.avatar || fake_photos}
                            />
                        </div>
                        <div className="right">
                            <div className="title">
                                {variable_1}
                            </div>
                            <div className="format">
                                <span>{variable_2}</span>
                                <span>{variable_3}</span>
                                <span>{variable_4}</span>
                            </div>
                        </div>
                    </div>
                    <label htmlFor="photo2">{variable_5}</label>
                    <input
                        ref={inputPhotoRef}
                        style={{display: "none"}}
                        id="photo2"
                        type="file"
                        accept="image/png, image/jpeg, image/svg"
                        className={"input_photo"}
                        onChange={(e) => onImageChange(e)}
                    />
                </div>
                {trial_status !== null &&
                    <>
                        {trial_status === 0 || trial_status === 1
                            ? <div className="account_confirm" style={{color: "red"}}> {variable_94}</div>
                            : <div className="account_confirm" style={{color: "#00f900"}}> {variable_95}</div>
                        }
                    </>
                }
                <ReuseInput
                    value={profileObj.fio}
                    title={variable_6}
                    onChange={(e) => handleChangeFormProfile(e.target.value, "fio")}
                />
                <PhoneMask
                    title={variable_7}
                    data={profileObj}
                    activeMaskPhone={profileObj.activeMaskPhone}
                    setChangeValue={handleChangeFormProfile}
                    handleChangeFlags={handleChangeFlags}
                    handleChangeFormProfile={handleChangeFormProfile}
                    flags={flags}
                />
                {/*<ReuseInput
                    value={profileObj.email}
                    title={"E-mail"}
                    onChange={(e) => handleChangeFormProfile(e.target.value, "email")}
                />*/}
                <ReuseInput
                    value={profileObj.telegram}
                    title={"Telegram"}
                    onChange={(e) => handleChangeFormProfile(e.target.value, "telegram")}
                />
                <ReuseInput
                    value={profileObj.whatsapp}
                    title={"WhatsApp"}
                    onChange={(e) => handleChangeFormProfile(e.target.value, "whatsapp")}
                />
                <ReuseInput
                    value={profileObj.vk}
                    title={"VK"}
                    onChange={(e) => handleChangeFormProfile(e.target.value, "vk")}
                />
                <div className={"language"}>
                    <div
                        className={flags.dropdownLanguage ? "drop_down-language active" : "drop_down-language"}
                        onClick={() => handleChangeFlags(!flags.dropdownLanguage, "dropdownLanguage")}
                    >
                        {arrLanguages[profileObj.language === "en" ? 1 : 0]}
                        <ArrowLanguage className={flags.dropdownLanguage ? "active" : ""}/>
                        <div
                            className={flags.dropdownLanguage ? "drop_down-block active" : "drop_down-block"}>
                            <ul>
                                {arrLanguages}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mailing">
                    <span>{variable_15}</span>
                    <label className="switch" htmlFor="checkbox_mailing">
                        <input
                            type="checkbox"
                            id="checkbox_mailing"
                            checked={profileObj.mailing}
                            onChange={(e) => {
                                handleChangeFormProfile(e.currentTarget.checked, "mailing");
                            }}
                        />
                        <div className="slider round"/>
                    </label>
                </div>
                <div
                    onClick={handleEditProfile}
                    className="btn_save-changed button_classic"
                >{variable_16}
                </div>
            </div>
            <div className="organization_block">
                {(trial_status === 0 || trial_status === 1) && width > 820 && <Trial hideBtn={true}/>}
                <div className="h2">{variable_17}</div>
                <div className="form_block">
                    <ReuseInput
                        value={organizationValues.short_name}
                        title={variable_18}
                        onChange={(e) => setOrganizationValues((prev) => ({...prev, ["short_name"]: e.target.value}))}
                        placeholder={variable_19}
                        required={true}
                    />
                    <div className="info_short">{variable_20}</div>
                    <div className="title_about">{variable_21} <span style={{color: "#FF3434"}}>*</span>
                    </div>
                    <TextArea
                        className={"textarea_antd"}
                        bordered={false}
                        value={organizationValues.contacts}
                        onChange={(e) => {
                            setOrganizationValues((prev) => ({...prev, ["contacts"]: e.target.value}));
                        }}
                        placeholder={variable_19}
                    />
                    <div className="info_short">{variable_22}</div>
                    <div
                        onClick={handleSendOrganication}
                        className="btn_send button_classic"
                    >
                        {registerInfo !== null
                            ? variable_16
                            : variable_24
                        }
                    </div>
                </div>
                <div className="h2">
                    <span>{variable_26}</span>
                    {clientProfile?.status !== "approved" &&
                        <>
                            <div style={{color: "var(--text_color_dark", marginLeft: "5px"}}>(не подтверждена)</div>
                        </>
                    }
                    {!flags.disabledAll &&
                        <div className="toggle_organization">
                            <span
                                className={flags.organization_toggle === "organization" && "active"}>{variable_17}</span>
                            <label className="switch" htmlFor="checkbox_organization">
                                <input
                                    type="checkbox"
                                    id="checkbox_organization"
                                    checked={flags.organization_toggle === "ip"}
                                    onChange={(e) => {
                                        if (e.target.checked)
                                            setFlags(prev => ({...prev, ["organization_toggle"]: "ip"}))
                                        else
                                            setFlags(prev => ({...prev, ["organization_toggle"]: "organization"}))
                                    }}
                                />
                                <div className="slider round"/>
                            </label>
                            <span className={flags.organization_toggle === "ip" && "active"}>{variable_90}</span>
                        </div>
                    }
                </div>

                {(trial_status === 0 || trial_status === 1) &&
                    <div className="warning_account-confirm">({variable_89})</div>
                }
                <div
                    className="client_profile"
                    ref={organizationDataRef}
                    style={focusOnOrganizationBlock ? {border: "1px solid #3077FF"} : {}}
                >
                    <ReuseRowClient
                        title={flags.organization_toggle === "organization" ? variable_36 : variable_87}
                        variable={"full_name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_36}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                        ref={inputFullNameRef || undefined}
                    />
                    <ReuseRowClient
                        title={flags.organization_toggle === "organization" ? variable_37 : variable_88}
                        variable={"name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_37}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_38}
                        variable={"email"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_38}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_39}
                        variable={"billing_descriptor"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info="Укажите название магазина в СМС и на странице проверки 3DS на иностранном языке"
                        errors={errors}
                        whiteSpaceOff={true}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_40}
                        variable={"inn"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_41}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    {flags.organization_toggle === "organization" &&
                        <ReuseRowClient
                            title={variable_42}
                            variable={"kpp"}
                            clientProfile={clientProfile}
                            setClientProfile={setClientProfile}
                            info={variable_43}
                            errors={errors}
                            validateFieldClient={validateFieldClient}
                            flags={flags}
                            required={false}
                        />
                    }
                    <ReuseRowClient
                        title={flags.organization_toggle === "organization" ? variable_44 : variable_91}
                        variable={"ogrn"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_45}
                        errors={errors}
                        setErrors={setErrors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                        customLength={flags.organization_toggle === "organization" ? undefined : 15}
                    />
                    <ReuseRowClient
                        title={variable_46}
                        variable={"account"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_47}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_48}
                        variable={"kor_account"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_49}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_50}
                        variable={"bank_name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_51}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                    <ReuseRowClient
                        title={variable_52}
                        variable={"bik"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info={variable_53}
                        errors={errors}
                        validateFieldClient={validateFieldClient}
                        flags={flags}
                    />
                </div>
                <div className="h2">{variable_54}</div>
                <div className="client_profile">
                    <ReuseRowClient
                        title={variable_56}
                        variable={"ceo_last_name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        needIconInfo={false}
                    />
                    <ReuseRowClient
                        title={variable_55}
                        variable={"ceo_first_name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        needIconInfo={false}
                    />
                    <ReuseRowClient
                        title={variable_57}
                        variable={"ceo_middle_name"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        needIconInfo={false}
                        required={false}
                    />
                    <ReuseRowClient
                        title={variable_58}
                        variable={"ceo_birth_date"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        setFlags={setFlags}
                        needIconInfo={false}
                    />
                    <ReuseRowClient
                        title={variable_59}
                        variable={"ceo_phone"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        setFlags={setFlags}
                        needIconInfo={false}
                    />
                    <ReuseRowClient
                        title={variable_60}
                        variable={"ceo_country"}
                        clientProfile={clientProfile}
                        setClientProfile={setClientProfile}
                        info=""
                        errors={errors}
                        flags={flags}
                        setFlags={setFlags}
                        needIconInfo={false}
                    />
                </div>
                {flags.organization_toggle === "organization" &&
                    <>
                        <div className="h2">{variable_61}</div>
                        <div className="adress_block"
                        >
                            <ReuseRowClientAdress
                                title={variable_62}
                                variable={"zip"}
                                clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "legal") : {}}
                                setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "legal")}
                                info=""
                                errors={errors}
                                classNameFlag={"drop_down_country"}
                                validateFieldAdress={validateFieldAdress}
                                flags={flags}
                                needIconInfo={false}
                            />
                            <ReuseRowClientAdress
                                title={variable_63}
                                variable={"country"}
                                clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "legal") : {}}
                                setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "legal")}
                                info=""
                                errors={errors}
                                setFlags={setFlags}
                                classNameFlag={"drop_down_country"}
                                flags={flags}
                                displayCurrentCountry={displayCurrentCountry(clientAdress.length > 0 ? clientAdress.find(x => x.type === "legal").country : "")}
                                needIconInfo={false}
                            />
                            <ReuseRowClientAdress
                                title={variable_64}
                                variable={"city"}
                                clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "legal") : {}}
                                setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "legal")}
                                info=""
                                errors={errors}
                                classNameFlag={"drop_down_country"}
                                flags={flags}
                                needIconInfo={false}
                            />
                            <ReuseRowClientAdress
                                title={variable_65}
                                variable={"street"}
                                clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "legal") : {}}
                                setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, regExStreet(value), "legal")}
                                info=""
                                errors={errors}
                                classNameFlag={"drop_down_country"}
                                flags={flags}
                                needIconInfo={false}
                            />
                        </div>
                    </>
                }
                <>
                    <div className="h2_checkbox">
                        <div className="h2">{variable_66}</div>
                        {getLegalFields() &&
                            <ReuseCheckbox
                                value={flags.checked_checkbox}
                                title={"Соответствует юридическому"}
                                change={(e) => {
                                    if (e) {
                                        setFlags(prev => ({...prev, ["checked_checkbox"]: e}));
                                        setFlags(prev => ({...prev, ["disabledFact"]: e}));

                                        let newArr = [...clientAdress];
                                        clientAdress[1].zip = clientAdress[0].zip;
                                        clientAdress[1].city = clientAdress[0].city;
                                        clientAdress[1].street = clientAdress[0].street;
                                        clientAdress[1].country = clientAdress[0].country;

                                        setClientAdress(newArr);
                                        setClientAdress(newArr);
                                    }
                                    if (!e) {
                                        setFlags(prev => ({...prev, ["checked_checkbox"]: e}));
                                        setFlags(prev => ({...prev, ["disabledFact"]: e}));
                                    }
                                }}
                                indx={3}
                            />
                        }
                    </div>
                    <div className="adress_block">
                        <ReuseRowClientAdress
                            title={variable_62}
                            variable={"zip"}
                            clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "actual") : {}}
                            setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "actual")}
                            info=""
                            errors={errors}
                            classNameFlag={"drop_down_country_fact"}
                            validateFieldAdress={validateFieldAdress}
                            flags={flags}
                            needIconInfo={false}
                            required={flags.organization_toggle === "ip"}
                        />
                        <ReuseRowClientAdress
                            title={variable_63}
                            variable={"country"}
                            clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "actual") : {}}
                            setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "actual")}
                            info=""
                            errors={errors}
                            setFlags={setFlags}
                            classNameFlag={"drop_down_country_fact"}
                            flags={flags}
                            displayCurrentCountry={displayCurrentCountry(clientAdress.length > 0 ? clientAdress.find(x => x.type === "actual").country : "")}
                            needIconInfo={false}
                            required={flags.organization_toggle === "ip"}
                        />
                        <ReuseRowClientAdress
                            title={variable_64}
                            variable={"city"}
                            clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "actual") : {}}
                            setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, value, "actual")}
                            info=""
                            errors={errors}
                            classNameFlag={"drop_down_country_fact"}
                            flags={flags}
                            needIconInfo={false}
                            required={flags.organization_toggle === "ip"}
                        />
                        <ReuseRowClientAdress
                            title={variable_65}
                            variable={"street"}
                            clientAdress={clientAdress.length > 0 ? clientAdress.find(x => x.type === "actual") : {}}
                            setClientAdress={(currentId, currentStr, value) => handleEditAdress(currentId, currentStr, regExStreet(value), "actual")}
                            info=""
                            errors={errors}
                            classNameFlag={"drop_down_country_fact"}
                            flags={flags}
                            needIconInfo={false}
                            required={flags.organization_toggle === "ip"}
                        />
                    </div>
                </>
                {!flags.disabledAll && flags.loading
                    ? <Spin size={'large'} className={"spin-blue"}/>
                    : <div
                        className="btn_green button_classic"
                        onClick={() => createOrganizationStore(flags.organization_toggle === 'ip')}
                    >
                        {variable_35}
                    </div>
                }
            </div>
            {/*{flags.loading &&*/}
            {/*    <Spin*/}
            {/*        className={"spin spin-blue"}*/}
            {/*        size={'large'}*/}
            {/*        style={{*/}
            {/*            position: "absolute",*/}
            {/*            top: "25%",*/}
            {/*            left: "50%",*/}
            {/*            zIndex: 10*/}
            {/*        }}*/}
            {/*    />*/}
            {/*}*/}
            {flags.flagModalEditImage &&
                <Modal
                    visible={flags.flagModalEditImage}
                    footer={null}
                    className={"modal_edit-image profile_img"}
                    closable={true}
                    closeIcon={<div><img src={close_modal} alt=""/></div>}
                    onCancel={() => setFlags((prev) => ({...prev, ["flagModalEditImage"]: false}))}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: "50px 50px 40px 50px", backgroundColor: "#343843", borderRadius: 35}}
                >
                    <ImageCrop
                        customRequest={(value) => customRequest(value, true)}
                        imageSrcProps={imageSrcProps}
                    />
                </Modal>
            }
            {(flags.send_store_fail) &&
                <Modal
                    visible={flags.send_store_fail}
                    footer={null}
                    className={"modal_edit-image profile_img"}
                    closable={true}
                    closeIcon={<div><img src={close_modal} alt=""/></div>}
                    onCancel={() => setFlags((prev) => ({...prev, ["send_store_fail"]: false}))}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: "50px 50px 40px 50px", backgroundColor: "#343843", borderRadius: 35}}
                >
                    <div style={{display: 'flex', flexDirection:"column", alignItems: 'center', fontSize: '32px', gap: '10px'}}>
                        <div>Ошибка отправки данных</div>
                        <div>Повторите попытку</div>
                        <div
                            className="button_classic"
                            onClick={() => setFlags((prev) => ({...prev, ["send_store_fail"]: false}))}
                            style={{width: '100%'}}
                        >
                            {"Ок"}
                        </div>
                    </div>
                </Modal>
            }
            <>
                <div
                    className={flags.dropdownPhone || flags.dropdownLanguage ? "overlay open" : "overlay"}
                    onClick={() => {
                        handleChangeFlags(false, "dropdownPhone");
                        handleChangeFlags(false, "dropdownLanguage");
                    }}
                />
            </>
        </div>
    );
};

export default Profile;