// import React from 'react';
// import cn from "classnames";
// import "./Button.scss";
//
// const Button = (props) => {
//     const {
//         text, click, maxWidth, margin, padding, backgroundColor,
//         boxShadow, keyDown, tabIndex, zIndex, refProps, border,
//         borderRadius, width = "", disabled = false, htmlType = undefined,
//         className = "",
//     } = props;
//
//     return (
//         <button
//             className={cn(`button ${className}`, {
//                 disabled
//             })}
//             ref={refProps}
//             onClick={(e) => {
//                 if (typeof click === "function" && !disabled)
//                     click(e)
//             }}
//             onKeyDown={(event) => {
//                 if (typeof keyDown !== "undefined")
//                     keyDown(event)
//             }}
//             tabIndex={tabIndex ? 0 : undefined}
//             style={{
//                 margin: margin || "",
//                 padding: padding || "11px 12px 12px 12px",
//                 width: width || "100%",
//                 maxWidth: maxWidth || "100%",
//                 boxShadow: boxShadow || "0 9px 20px rgba(48, 119, 255, 0.45)",
//                 backgroundColor: backgroundColor || "#3077FF",
//                 zIndex: zIndex || 0,
//                 border: border,
//                 borderRadius
//             }}
//             type={htmlType}
//         >
//             <div className="text_in">{text}</div>
//         </button>
//     );
// };
//
// export default Button;
import cn from "classnames";
import "./Button.scss";

const Button = (props) => {
    const {
        text, click, width = "100%", maxWidth, margin, padding,
        height, keyDown, tabIndex, zIndex, refProps,
        disabled = false, htmlType = undefined,
        className = "", icon = null, colorVariant = 'blue'
    } = props;
    // icon = {position: 'left/right', name: 'exel'}
    const style = {}
    if (height) style.height = height
    if (width) style.width = width
    if (maxWidth) style.maxWidth = maxWidth
    if (margin) style.margin = margin
    if (padding) style.padding = padding
    if (zIndex) style.zIndex = zIndex

    const pasteIcon = (paste, name) => {
        if (paste) {
            if (name === 'exel') return <svg className="button_img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5H21C21.1989 4.5 21.3897 4.57902 21.5303 4.71967C21.671 4.86032 21.75 5.05109 21.75 5.25V9H20.25V6H3.75V9H15.5V10.5H9V13.5H14V15H9V18H14V19.5H3.75C3.35218 19.5 2.97064 19.342 2.68934 19.0607C2.40804 18.7794 2.25 18.3978 2.25 18V5.25C2.25 5.05109 2.32902 4.86032 2.46967 4.71967C2.61032 4.57902 2.80109 4.5 3 4.5ZM7.5 10.5H3.75V13.5H7.5V10.5ZM7.5 15H3.75V18H7.5V15Z" fill="white"/>
                <path d="M22.9251 15.6171C22.8748 15.4957 22.801 15.3854 22.7081 15.2925C22.6152 15.1995 22.5049 15.1258 22.3835 15.0754C22.2621 15.0251 22.132 14.9992 22.0006 14.9992C21.8691 14.9992 21.739 15.0251 21.6176 15.0754C21.4962 15.1258 21.3859 15.1995 21.2931 15.2925L20.0006 16.5863V11C20.0006 10.7348 19.8952 10.4804 19.7077 10.2929C19.5201 10.1054 19.2658 10 19.0006 10C18.7353 10 18.481 10.1054 18.2934 10.2929C18.1059 10.4804 18.0006 10.7348 18.0006 11V16.5863L16.7081 15.2925C16.5204 15.1049 16.2659 14.9994 16.0006 14.9994C15.7352 14.9994 15.4807 15.1049 15.2931 15.2925C15.1054 15.4801 15 15.7346 15 16C15 16.2654 15.1054 16.5199 15.2931 16.7075L18.2931 19.7075C18.3859 19.8005 18.4962 19.8742 18.6176 19.9246C18.739 19.9749 18.8691 20.0008 19.0006 20.0008C19.132 20.0008 19.2621 19.9749 19.3835 19.9246C19.5049 19.8742 19.6152 19.8005 19.7081 19.7075L22.7081 16.7075C22.801 16.6146 22.8748 16.5043 22.9251 16.3829C22.9754 16.2615 23.0013 16.1314 23.0013 16C23.0013 15.8686 22.9754 15.7385 22.9251 15.6171Z" fill="white"/>
            </svg>
            if (name === 'resizeMax') return <svg className="button_img" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 11C1 6.286 1 3.929 2.464 2.464C3.93 1 6.286 1 11 1C15.714 1 18.071 1 19.535 2.464C21 3.93 21 6.286 21 11C21 15.714 21 18.071 19.535 19.535C18.072 21 15.714 21 11 21C6.286 21 3.929 21 2.464 19.535C1 18.072 1 15.714 1 11Z" stroke="white" stroke-width="1.5"/>
                <path d="M16 6H13M16 6V9M16 6L12.5 9.5M6 16H9M6 16V13M6 16L9.5 12.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            if (name === 'resizeMin') return <svg className="button_img" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 11C1.5 6.286 1.5 3.929 2.964 2.464C4.43 1 6.786 1 11.5 1C16.214 1 18.571 1 20.035 2.464C21.5 3.93 21.5 6.286 21.5 11C21.5 15.714 21.5 18.071 20.035 19.535C18.572 21 16.214 21 11.5 21C6.786 21 4.429 21 2.964 19.535C1.5 18.072 1.5 15.714 1.5 11Z" stroke="white" stroke-width="1.5"/>
                <path d="M10 12.5H7M10 12.5V15.5M10 12.5L6.5 16M13 9.5H16M13 9.5V6.5M13 9.5L16.5 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            if (name === 'add_question') return <svg width="20" height="20" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.833252 5.50016H10.1666M5.49992 10.1668V0.833496" stroke="white" stroke-linecap="round"/>
            </svg>
            if (name === 'copy_link') return <svg className="button_img" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke-width="1.6" d="M19.1177 8.22607H10.1177C9.01311 8.22607 8.11768 9.1215 8.11768 10.2261V19.2261C8.11768 20.3306 9.01311 21.2261 10.1177 21.2261H19.1177C20.2222 21.2261 21.1177 20.3306 21.1177 19.2261V10.2261C21.1177 9.1215 20.2222 8.22607 19.1177 8.22607Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path stroke-width="1.6" d="M4.11768 14.2261H3.11768C2.58724 14.2261 2.07853 14.0154 1.70346 13.6403C1.32839 13.2652 1.11768 12.7565 1.11768 12.2261V3.22607C1.11768 2.69564 1.32839 2.18693 1.70346 1.81186C2.07853 1.43679 2.58724 1.22607 3.11768 1.22607H12.1177C12.6481 1.22607 13.1568 1.43679 13.5319 1.81186C13.907 2.18693 14.1177 2.69564 14.1177 3.22607V4.22607" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            if (name === 'settings_mobile') return <svg className="button_img" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 9.66675H11.3333M23 9.66675H14.6667M8 15.5001H18M23 15.5001H21.3333M8 21.3334H9.66667M23 21.3334H13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M12.9997 11.3333C13.9201 11.3333 14.6663 10.5871 14.6663 9.66667C14.6663 8.74619 13.9201 8 12.9997 8C12.0792 8 11.333 8.74619 11.333 9.66667C11.333 10.5871 12.0792 11.3333 12.9997 11.3333Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M19.6667 17.1666C20.5871 17.1666 21.3333 16.4204 21.3333 15.4999C21.3333 14.5794 20.5871 13.8333 19.6667 13.8333C18.7462 13.8333 18 14.5794 18 15.4999C18 16.4204 18.7462 17.1666 19.6667 17.1666Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M11.3337 23.0001C12.2541 23.0001 13.0003 22.2539 13.0003 21.3334C13.0003 20.4129 12.2541 19.6667 11.3337 19.6667C10.4132 19.6667 9.66699 20.4129 9.66699 21.3334C9.66699 22.2539 10.4132 23.0001 11.3337 23.0001Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
            </svg>
            if (name === 'RU') return <svg className={"shadow"} width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="25" height="6" fill="white"/>
                <rect x="0" y="6" width="25" height="6" fill="#0F56E0"/>
                <rect x="0" y="12" width="25" height="6" fill="#D22222"/>
            </svg>
            if (name === 'EN') return <svg className={"shadow"} width="25" height="18" viewBox="0 0 25 18" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="18" fill="#00247D"/>
                <polygon points="0,0 25,18 22,18 0,2" fill="white"/>
                <polygon points="25,0 0,18 3,18 25,2" fill="white"/>
                <polygon points="0,0 25,18 21,18 0,3" fill="#CF142B"/>
                <polygon points="25,0 0,18 4,18 25,3" fill="#CF142B"/>
                <rect x="10" width="5" height="18" fill="white"/>
                <rect y="7" width="25" height="4" fill="white"/>
                <rect x="11" width="3" height="18" fill="#CF142B"/>
                <rect y="8" width="25" height="2" fill="#CF142B"/>
            </svg>
            if (name === 'SA') return <svg className={"shadow"} width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="25" height="12" fill="#009DFE"/>
                <rect x="0" y="15" width="25" height="3" fill="#009B01"/>
                <rect x="0" y="13" width="25" height="2" fill="#FE0201"/>
                <rect x="0" y="11" width="25" height="2" fill="white"/>
                <circle cx="12.5" cy="5" r="3.5" fill="white"/>
            </svg>
        }
    }

    return (
        <button
            className={cn(`button  ${className + ' ' + colorVariant}`, {
                disabled
            })}
            ref={refProps}
            onClick={(e) => {
                if (typeof click === "function")// && !disabled
                    click(e);
            }}
            disabled={disabled}
            onKeyDown={(event) => {
                if (typeof keyDown !== "undefined")
                    keyDown(event);
            }}
            tabIndex={tabIndex ? 0 : undefined}
            style={style}
            type={htmlType}
        >
            {pasteIcon(icon?.position === 'left', icon?.name)}
            <div className="text_in">{text}</div>
            {pasteIcon(icon?.position === 'right', icon?.name)}
        </button>
    );
};

export default Button;